import React from 'react';
import { Route, Switch } from 'react-router-dom';


import CheckinsPage from "./pages/Checkins";
import TablesPage from "./pages/Tables";
import SettingsPage from "./pages/Settings";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={CheckinsPage} />
        <Route path='/checkins' component={CheckinsPage} />
        <Route path='/tables' component={TablesPage} />
        <Route path='/settings' component={SettingsPage} />
       
        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
