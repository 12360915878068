import React, { useState, useEffect } from 'react';
import { MDBDataTableV5, MDBContainer, MDBBtn, MDBCard, MDBCardBody, MDBDatePicker, MDBTimePicker, MDBSelect, MDBInput, MDBRow, MDBCol, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';

import MainSettings from "./Settings/MainSettings";
import CheckinSettings from "./Settings/CheckinSettings";
export default function Settings() {
  // Init Variables
  const [activeTab, setActiveTab] = useState("checkin");
  const togglePills = (tab) => e => {
    e.preventDefault();
    setActiveTab(tab);
  };

  // Component Render
  return (
      <>
      <MDBContainer className="mb-5">
        <MDBNav pills className="nav-justified pills-quepal-gradient">
        <MDBNavItem>
            <MDBNavLink
              link
              to="#"
              active={activeTab === "checkin"}
              onClick={togglePills("checkin")}
            >
              Check-In Seite
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              link
              to="#"
              active={activeTab === "main"}
              onClick={togglePills("main")}
            >
              Account
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              link
              to="#"
              active={activeTab === "subscription"}
              onClick={togglePills("subscription")}
            >
              Abonnement und Zahlung
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
      </MDBContainer>
      {activeTab === "main" && <MainSettings />}
      {activeTab === "checkin" && <CheckinSettings />}
    </>
  );
}