import React from 'react';
import {  MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';

export default function ViewCheckin(props) {
    console.log(props.checkin)
  // Component Render
  const checkin = props.checkin || {};
  return (
    <MDBModal isOpen={props.isOpen} toggle={() => props.setOpen(false)} centered>
        <MDBModalHeader toggle={() => props.setOpen(false)}>Checkin am Tisch: {checkin.tableId}</MDBModalHeader>
        <MDBModalBody>
            <div className="mx-auto">
             <table>
                 <tbody>
                     <tr>
                         <td>Zeit:</td>
                         <td>{checkin.timestamp && (new Date(checkin.timestamp)).toLocaleString('de-DE', { timeZone: 'Europe/Berlin' })}</td>
                     </tr>
                     <tr>
                         <td>Tisch:</td>
                         <td>{checkin.tableId}</td>
                     </tr>
                     <tr>
                         <td>Personenanzahl:</td>
                         <td>{checkin.persons && checkin.persons.length}</td>
                     </tr>
                     <tr>
                         <td>Personen:</td>
                         <td>
                             <ul>
                                 {checkin.persons && checkin.persons.map(person => <li>{person.firstName} {person.lastName}<br></br>{person.zip}<br></br>{person.phone}<br></br>{person.status} Datum: {person.date}</li>)}
                             </ul>
                         </td>
                     </tr>
                 </tbody>
             </table>
            </div>
        </MDBModalBody>
        <MDBModalFooter>
            <MDBBtn color="danger" onClick={() => props.setOpen(false)}>Schließen</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
  );
};