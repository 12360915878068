import React, { useState, useEffect } from 'react';
import {API, Auth, JS} from 'aws-amplify';
import {  MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCard, MDBInput, MDBRow, MDBCol, MDBIcon, toast, ToastContainer } from 'mdbreact';
import { ChromePicker } from 'react-color'
import {useDropzone} from 'react-dropzone';
export default function MainSettings() {
    const [userName, setUserName] = useState("");
    const getUsername = async () => {
        const auth = await Auth.currentUserInfo();
        setUserName(auth.username);
    }
    const [storeColor, setStoreColor] = useState("");
    const [logoUrl, setLogoUrl] = useState("");

    // Input Fields
    const [name, setname] = useState("");
    const [address, setaddress] = useState("");
    const [zip, setzip] = useState("");
    const [city, setcity] = useState("");
    const [country, setcountry] = useState("");
    const [menuUrl, setmenuUrl] = useState("");

    // Get and update restaurant data
    const getRestaurant = async () => {
        const auth = await Auth.currentUserInfo();
        const r = await API.get("FoodHive-Checkin-API", "/restaurant", {queryStringParameters: {restaurantId: auth.username}});
        setStoreColor(r.mainColor || "#2196f3");
        if(r.logoUrl) setLogoUrl(r.logoUrl);
        setname(r.name); setaddress(r.address); setzip(r.zip); setcity(r.city); setcountry(r.country); setmenuUrl(r.menuUrl);
      };

    const updateRestaurant = async () => {
        try {
            await API.post("FoodHive-Checkin-API", "/restaurant", {body: {restaurant: {name, address, zip, city, country, menuUrl, mainColor: storeColor}}});
            toast.success('Restaurant aktualisiert', {
                closeButton: false
              });
        } catch (error) {
            toast.error(error.message, {
                closeButton: false
              });
        }
    };
    
    const uploadMainImage = async (img) => {
        try {
            const buff = Buffer.from(await readFileAsync(img), 'utf-8');
            const base64 = buff.toString('base64');
            const url = await API.post("FoodHive-Checkin-API", "/media/logo", {body: {type: img.type, data: base64}});
            setLogoUrl(url);
        } catch (e) {
          console.log(e)
        }
      }

    const MainImage = (props) => {
        const [file, setFile] = useState("");
        const {getRootProps, getInputProps} = useDropzone({
            accept: 'image/*',
            onDrop: acceptedFiles => {
                setFile(Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0])
            }));
            uploadMainImage(acceptedFiles[0]);
            }
        });
        
        return (
            <section className="container">
            <div {...getRootProps({className: 'dropzone text-center mt-4'})}>
                <input {...getInputProps()} />
                <img alt="Main"
                src={logoUrl.length > 0 ? logoUrl : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                className="img-thumbnail mx-auto"
                />
                <MDBIcon icon="cloud-upload-alt" className="mt-3" />
                <p>Optimale Größe: 150 x 85 px</p>
            </div>
            </section>
        );
    }

    useEffect(() => {
        getRestaurant();
        getUsername();
    }, []);

  return (
      <>
    <MDBCard>
        <MDBModalBody>
        <MDBRow>
            <MDBCol size="12" md="6" className="p-2">
                <h6 className="text-center">Unternehmen</h6>
                <MDBInput label="Firmenname" value={name} onChange={e => setname(e.target.value)} />
                <MDBInput label="Straße und Hausnummer" value={address} onChange={e => setaddress(e.target.value)} />
                <MDBRow>
                    <MDBCol size="6">
                        <MDBInput label="Postleitzahl" value={zip} onChange={e => setzip(e.target.value)} />
                    </MDBCol>
                    <MDBCol size="6">
                        <MDBInput label="Stadt" value={city} onChange={e => setcity(e.target.value)} />
                    </MDBCol>
                </MDBRow>
                <MDBInput label="Land" value={country} onChange={e => setcountry(e.target.value)} />
                <br></br>
                <MDBInput label="Link Speisekarte" value={menuUrl} onChange={e => setmenuUrl(e.target.value)} />
                <MDBBtn onClick={updateRestaurant}>Speichern</MDBBtn>
                <a target="_blank" rel="noopener noreferrer" href={"https://checkin.customersign.de/?storeId=" + userName}><MDBBtn>Vorschau</MDBBtn></a>
            </MDBCol>
            <MDBCol size="12" md="3" className="p-2">
                <h6 className="text-center">Farbschema</h6>
                <ChromePicker
                className=" mt-4 mx-auto"
                color={storeColor}
                onChangeComplete={e => setStoreColor(e.hex)}
                 />
            </MDBCol>
            <MDBCol size="12" md="3" className="p-2">
                <h6 className="text-center">Logo</h6>
                <MainImage />
            </MDBCol>
        </MDBRow>

            
        </MDBModalBody>
    </MDBCard>
    <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
    </>
  );
};

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
  
      reader.readAsArrayBuffer(file);
    })
  }