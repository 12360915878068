import React, { useState, useEffect } from 'react';
import {  MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCard, MDBInput, MDBRow, MDBCol } from 'mdbreact';

export default function MainSettings() {
    // UserName for QR Link Generation
    const [userName, setUserName] = useState("");

  return (
    <MDBCard>
        <MDBModalBody>
        <MDBRow>
            <MDBCol size="12" md="6" className="p-2">
                <h6 className="text-center">Unternehmen</h6>
                <MDBInput label="Firmenname" />
                <MDBInput label="Straße und Hausnummer" />
                <MDBRow>
                    <MDBCol size="6">
                        <MDBInput label="Postleitzahl" />
                    </MDBCol>
                    <MDBCol size="6">
                        <MDBInput label="Stadt" />
                    </MDBCol>
                </MDBRow>
                <MDBInput label="Land" />
            </MDBCol>
            <MDBCol size="12" md="6" className="p-2">
                <h6 className="text-center">Ansprechpartner</h6>
                <MDBInput label="Vorname" />
                <MDBInput label="Nachname" />
                <MDBInput label="Email" />
                <MDBInput label="Telefon" />
            </MDBCol>
        </MDBRow>
            
        </MDBModalBody>
    </MDBCard>
  );
};