import React, { useState, useEffect } from 'react';
import { MDBDataTableV5, MDBContainer, MDBBtn, MDBCard, MDBCardBody, MDBDatePicker, MDBTimePicker, MDBSelect } from 'mdbreact';
import {API} from 'aws-amplify';
import ModalViewCheckin from "./Checkins/ViewCheckin";
import ModalRemoveCheckin from "./Checkins/RemoveCheckin";

export default function Checkins() {
  // Init Variables
  const columns = [
    {
      label: 'Zeitpunkt',
      field: "time",
      width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'Tisch',
      field: 'table',
      width: 270,
    },
    {
      label: 'Personenanzahl',
      field: 'count',
      width: 200,
    },
    {
      label: 'Namen',
      field: 'names',
      width: 100,
    },
    {
      label: 'Aktion',
      field: 'action',
      width: 150,
    }
  ];
  const [datatable, setDatatable] = useState([]);
  const [availableTables, setAvailableTables] = useState([]);

  // Get Checkins
  const getCheckins = async (filterParams) => {
    const checkins = await API.get("FoodHive-Checkin-API", "/checkins", {queryStringParameters: filterParams || {}});
    setDatatable(checkins.reverse().map(checkin => ({
      time: (new Date(checkin.timestamp)).toLocaleString('de-DE', { timeZone: 'Europe/Berlin' }),
      table: checkin.tableId,
      count: checkin.persons.length,
      names: checkin.persons.map(person => person.firstName + " " + person.lastName).join(", "),
      action: <div className="text-center">
                <MDBBtn onClick={() => {setViewCheckinOpen(true); setSelectedCheckin(checkin)}}>Anzeigen</MDBBtn>
                <MDBBtn onClick={() => {setViewRemoveCheckin(true); setSelectedCheckin(checkin)}} color="danger">Entfernen</MDBBtn>
              </div>
    })));
  };
  useEffect(() => {
      async function getTables() {
        const tables = await API.get("FoodHive-Checkin-API", "/tables");
        setAvailableTables(tables.map(table => ({text: table.id, value: table.id})));
      }
      getCheckins();
  }, []);
  
  // Filter Variables and OnSubmit
  const [fromDate, setFromDate] = useState("");
  const [fromTime, setfromTime] = useState("");
  const [toDate, setToDate] = useState("");
  const [toTime, setToTime] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  
  const onFilterSubmit = () => {
    const filterParams = {
      from: (new Date(fromDate + "T" + fromTime)).getTime(),
      to: (new Date(toDate + "T" + toTime)).getTime()
    };
    if (selectedTable) filterParams.table = selectedTable;
    getCheckins(filterParams);
  };

  // Modals
  const [viewCheckinOpen, setViewCheckinOpen] = useState(false);
  const [viewRemoveCheckin, setViewRemoveCheckin] = useState(false);
  const [selectedCheckin, setSelectedCheckin] = useState({}); 

  // Page Render
  return (
      <>
        <MDBContainer className="mt-5">
          <h3 className="my-5 text-center">Checkin's</h3>
          <MDBCard className="mx-auto col-10 mb-5">
            <MDBCardBody>
              <table>
                <tbody>
                  <tr>
                    <td><h4 className="font-weight-bold px-4">Filter</h4></td>
                    <td className="px-2"><MDBDatePicker label="Datum von" format="DD.MM.YYYY" getValue={e => setFromDate(e.toISOString().substring(0, 10))} /></td>
                    <td className="px-2"><MDBTimePicker label="Uhrzeit von" hoursFormat={24} getValue={e => setfromTime(e)} /></td>
                    <td className="px-2"><MDBDatePicker label="Datum bis" format="DD.MM.YYYY" getValue={e => setToDate(e.toISOString().substring(0, 10))} /></td>
                    <td className="px-2"><MDBTimePicker label="Uhrzeit bis" hoursFormat={24} getValue={e => setToTime(e)} /></td>
                    {/* <td className="px-2"><MDBSelect label="Tisch" options={availableTables} getValue={e => setSelectedTable(e)} /></td> */}
                    <td><MDBBtn onClick={onFilterSubmit}>Laden</MDBBtn></td>
                  </tr>
                </tbody>
              </table>
            </MDBCardBody>
          </MDBCard>
          <MDBCard>
            <MDBCardBody className="text-center">
            <MDBBtn onClick={() => getCheckins() }>Aktualisieren</MDBBtn>
              <MDBDataTableV5 hover entriesOptions={[5, 25, 50, 100]} entries={25} pagesAmount={4} data={{columns, rows: datatable}} />
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
        <ModalViewCheckin isOpen={viewCheckinOpen} setOpen={setViewCheckinOpen} checkin={selectedCheckin} />
        <ModalRemoveCheckin isOpen={viewRemoveCheckin} setOpen={setViewRemoveCheckin} checkin={selectedCheckin} />
      </>
  );
}