import React, { Component } from 'react';
import Amplify, {Auth, Analytics} from 'aws-amplify';
import { AmplifyAuthenticator,AmplifySignUp} from '@aws-amplify/ui-react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBTooltip,
  MDBIcon,
  MDBContainer
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';


Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: "eu-central-1",
    userPoolId: "eu-central-1_njg8fspqm",
    userPoolWebClientId: "1pvcp1l9nnrksblh7drv4st001"
  },
  API: {
    endpoints: [
      {
        name: "FoodHive-Checkin-API",
        endpoint: "https://79v4ubfmq9.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => { 
          return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
        }
      }
    ]
  }
});

// Firebase 

const firebaseConfig = {
  apiKey: "AIzaSyAmHaSNjpRywVLwrw0aYjCp8ysqq9_vtRY",
  authDomain: "customersign-dfd1c.firebaseapp.com",
  databaseURL: "https://customersign-dfd1c.firebaseio.com",
  projectId: "customersign-dfd1c",
  storageBucket: "customersign-dfd1c.appspot.com",
  messagingSenderId: "862342520615",
  appId: "1:862342520615:web:6232b213b6da6e49b49302",
  measurementId: "G-8P0RNJK2GF"
};

class App extends Component {
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: '' });
  };

  render() {
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );

    const { collapseID } = this.state;

    return (
      <AmplifyAuthenticator>
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          {
            type: "username",
            label: "Benutzername",
            placeholder: "Benutzername",
            required: true,
          },
          {
            type: "email",
            label: "Email Adresse",
            placeholder: "Email Adresse",
            required: true,
          },
          {
            type: "given_name",
            key: "given_name",
            label: "Vorname",
            placeholder: "Vorname",
            required: true,
          },
          {
            type: "family_name",
            key: "family_name",
            label: "Nachname",
            placeholder: "Nachname",
            required: true,
          },
          {
            type: "phone_number",
            label: "Telefonnummer",
            placeholder: "Telefonnummer",
            required: false,
          },
          {
            type: "password",
            label: "Passwort",
            placeholder: "Passwort",
            required: true,
          },
        ]}

      ></AmplifySignUp>
          <Router>
        <div className='container'>
          <MDBNavbar color=' quepal-gradient' dark expand='md' fixed='top' scrolling>
          <MDBContainer>
          <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
              <strong className='align-middle'>Customersign</strong>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
            <MDBCollapse id='mainNavbarCollapse' isOpen={collapseID} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/checkins'
                  >
                    <strong>Check-in's</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/tables'
                  >
                    <strong>Tische</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/settings'
                  >
                    <strong>Einstellungen</strong>
                  </MDBNavLink>
                </MDBNavItem>
                
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
          </MDBNavbar>
          {collapseID && overlay}
          <main style={{ marginTop: '4rem' }}>
            <Routes />
          </main>
          
        </div>
        <MDBFooter color=' quepal-gradient' className="w-100" style={{position: "fixed"}}>
            <p className='mb-0 py-3 text-center'>
              &copy; {new Date().getFullYear()} Copyright:
              FoodHive UG
            </p>
          </MDBFooter>
      </Router>
      </AmplifyAuthenticator>
      
    );
  }
}

export default App;