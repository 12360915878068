import React from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import {API} from 'aws-amplify';

export default function QRCodeModal(props) {
  const checkin = props.checkin || {};

  const removeCheckin = async () => {
      await API.del("FoodHive-Checkin-API", "/checkins", {body: {checkin}});
      props.setOpen(false);
  }

  return (
    <MDBModal isOpen={props.isOpen} toggle={() => props.setOpen(false)} centered>
        <MDBModalHeader toggle={() => props.setOpen(false)}>Checkin am Tisch: {checkin.tableId}</MDBModalHeader>
        <MDBModalBody>
            <div className="mx-auto">
             <table>
                 <tbody>
                     <tr>
                         <td>Zeit:</td>
                         <td>{checkin.timestamp && (new Date(checkin.timestamp)).toLocaleString('de-DE', { timeZone: 'Europe/Berlin' })}</td>
                     </tr>
                     <tr>
                         <td>Tisch:</td>
                         <td>{checkin.tableId}</td>
                     </tr>
                     <tr>
                         <td>Personenanzahl:</td>
                         <td>{checkin.persons && checkin.persons.length}</td>
                     </tr>
                     <tr>
                         <td>Personen:</td>
                         <td>
                             <ul>
                                 {checkin.persons && checkin.persons.map(person => <li>{person.firstName} {person.lastName} - {person.zip} - {person.phone}</li>)}
                             </ul>
                         </td>
                     </tr>
                 </tbody>
             </table>
            <div className="w-100 text-center">
            <h4>Wirklich löschen?</h4>
            <MDBBtn color="danger" onClick={removeCheckin}>Löschen</MDBBtn>
            </div>
            </div>
        </MDBModalBody>
        <MDBModalFooter>
            <MDBBtn color="danger" onClick={() => props.setOpen(false)}>Schließen</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
  );
};