import React, { useState, useEffect } from 'react';
import {  MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import {Auth} from 'aws-amplify';
import QRCode from "qrcode.react";

export default function QRCodeModal(props) {
    // UserName for QR Link Generation
    const [userName, setUserName] = useState("");
    const getUsername = async () => {
        const auth = await Auth.currentUserInfo();
        setUserName(auth.username);
    }
    // QR Download
    const downloadQR = (tableName) => {
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr-code" + tableName + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    useEffect(() => {
        getUsername();
    }, []);
  // Component Render
  return (
    <MDBModal isOpen={props.isOpen} toggle={() => props.setOpen(false)} centered>
        <MDBModalHeader toggle={() => props.setOpen(false)}>QR-Code für Tisch: {props.table.name}</MDBModalHeader>
        <MDBModalBody>
            <div className="mx-auto text-center">
                <QRCode id={"qr-code"} value={"https://checkin.customersign.de/?storeId=" + userName + "&tableId=" + props.table.id} />
                <br></br>
                <MDBBtn onClick={() => downloadQR(props.table.name)} color="primary">Download</MDBBtn>
                <a target="_blank" rel="noopener noreferrer" href={"https://checkin.customersign.de/?storeId=" + userName + "&tableId=" + props.table.id}><MDBBtn color="secondary">Link öffnen</MDBBtn></a>
            </div>
        </MDBModalBody>
        <MDBModalFooter>
            <MDBBtn color="danger" onClick={() => props.setOpen(false)}>Schließen</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
  );
};