import React, { useState, useEffect } from 'react';
import { MDBDataTableV5, MDBContainer, MDBBtn, MDBCard, MDBCardBody, MDBDatePicker, MDBTimePicker, MDBSelect, MDBInput, MDBRow, MDBCol } from 'mdbreact';
import {API} from 'aws-amplify';
import QRModal from "./Tables/Qr-Code";

export default function Tables() {
  // Init Variables

  // Table config
  const columns = [
    {
      label: 'Name',
      field: "name",
      width: 550,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'Aktion',
      field: 'action'
    }
  ];
  const [datatable, setDatatable] = useState([]);

  // Modals
  const [isOpenQR, setIsOpenQR] = useState(false);
  const [selectedTable, setSelectedTable] = useState({}); 

  // Get Tables
  const getTables = async (filterParams) => {
    const tables = await API.get("FoodHive-Checkin-API", "/tables", {queryStringParameters: filterParams || {}});
    console.log(tables)
    setDatatable(tables.map(table => ({
      name: table.name,
      action: <div>
                <MDBBtn color="elegant" onClick={() => {setIsOpenQR(true); setSelectedTable({id: table.id, name: table.name})}}>QR-Code</MDBBtn>
                {/* <MDBBtn color="white" disabled>Aufsteller Bestellen</MDBBtn> */}
                <MDBBtn color="primary">Umbenennen</MDBBtn>
                <MDBBtn color="danger">Entfernen</MDBBtn>
              </div>
    })));
  };
  // Create Table
  const [newTableName, setNewTableName] = useState("");
  const createTable = async () => {
    await API.post("FoodHive-Checkin-API", "/tables", {body: {name: newTableName}});
    getTables();
  };
  // Updater
  useEffect(() => {
      getTables();
  }, []);
  

  // Component Render
  return (
      <>
      <MDBContainer className="mt-5">
        <h3 className="my-5 text-center">Tische</h3>
        <MDBCard className="mx-auto col-12 col-md-10 col-xl-8 mb-5">
          <MDBCardBody>
          <MDBRow>
              <MDBCol size="12" md="3" className="text-center"><h4 className="font-weight-bold px-4 mt-xl-2">Neuer Tisch:</h4></MDBCol>
              <MDBCol size="12" md="5"><MDBInput containerClass="mt-1 mb-0" onChange={e => setNewTableName(e.target.value)} /></MDBCol>
              <MDBCol size="12" md="4" className="text-center"><MDBBtn onClick={createTable}>Hinzufügen</MDBBtn></MDBCol>
          </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <MDBCard>
          <MDBCardBody>
            <MDBDataTableV5 hover entriesOptions={[5, 25, 50, 100]} entries={25} pagesAmount={4} data={{columns, rows: datatable}} />
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
      <QRModal isOpen={isOpenQR} setOpen={setIsOpenQR} table={selectedTable} />
    </>
  );
}